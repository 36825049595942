.settings {
  &__tags {
    margin-bottom: 20px;
  }

  &__input {
    width: 100% !important;
  }

  .ant-checkbox-inner {
    border-radius: 4px;
  }


}

.table-row {
  background-color: #ffffff;
  transition: background-color 2s;
}

.start-animate {
  background-color: #b2d7fa;
}