html {
  height: 100%;
  width: 100%;

}

body {
  height: 100%;
  width: 100%;

  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  background-color: #f0f2f5;

  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';

}



#root {
  height: 100%;
  //min-height: 100vh;
  width: 100%;
}