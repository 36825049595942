@import "../Colors.scss";

.operations {
    width: 100%;
    height: calc(100% - 50px);
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 0;
    margin-top: 10px;
    overflow: auto;
    overflow-x: hidden;

    .operation {
        .error {
            // box-shadow: 0px 0px 0px 2px red;
            border: 1px solid rgb(255, 19, 19);
        }

        width: 100%;
        display: flex;
        min-height: 50px;

        @media screen and (max-width: 450px) {
            display: block;
        }

        &__input {
            display: flex;
            width: 50%;
            height: 100%;

            @media screen and (max-width: 650px) {
                margin-top: 10px;
                width: 100%;
            }

            &__comment {
                width: 70%;
                font-size: 20px;
                margin-right: 10px;
                border-bottom: 1px solid rgb(197, 197, 197);
                padding-right: 10px;

            }

            &__d {
                width: 30%;
                margin-right: 10px;

                &__qty {
                    width: 100%;
                    font-size: 20px;

                    border-bottom: 1px solid rgb(197, 197, 197);
                    padding-right: 10px;

                }

            }

            &__delete {
                font-size: 19px;
                display: flex;
                align-items: center; // width: 100%;

                @media screen and (max-width: 450px) {
                    width: 40px
                }
            }
        }

        &__info {
            display: flex;
            width: 50%;

            @media screen and (max-width: 450px) {
                width: 100%;
            }

            &__code {
                display: flex;
                width: 20%;
                font-size: 20px;
                margin-right: 10px;
                border-right: 1px solid rgb(197, 197, 197);
                padding-right: 10px;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 450px) {
                    width: none;
                }

            }

            &__title {
                font-size: 19px;
                display: flex;
                align-items: center;

                width: 70%;

                @media screen and (max-width: 450px) {
                    width: 80%
                }
            }

            &__delete {
                font-size: 19px;
                display: flex;
                align-content: left;

                // width: 100%;
                @media screen and (max-width: 450px) {
                    width: 40px
                }
            }
        }
    }


}