.card {
  // border-radius: 8px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  @media screen and (max-width: 450px) {
    margin-bottom: 20px !important;
    width: 100%;
    overflow: hidden;
    // margin-left: 0 !important;
    // margin-right: 0 !important;
  }
}

.ant-layout-content {
  @media screen and (max-width: 450px) {
    overflow-x: hidden;
  }
}
