@import "./Consts.scss";

.profile {
  height: 100%;
  width: 100%;
  @extend %div-center;
  align-items: normal;
  flex-direction: column;
  justify-content: flex-start;

  .profile__table {

    background-color: white;
  }

  .profile__right_bar {
    position: absolute;
    right: 0px;
    bottom: 0px;

    width: 20%;

    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;



    .right_bar__keyboard {
      width: 100%;

      background-color: white;
      padding: 5px;
      height: 60%;
      max-height: 300px;
    }
  }

  .profile__info_header {
    height: 50px;
    width: 100%;

  }

  .profile__info {
    margin-left: 10px;
    width: 100%;
    height: calc(100% - 50px);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;




    .profile__info_inputs {
      width: 70%;
      max-width: 600px;

      margin-top: 50px;
    }

  }
}