.filter-field {
    // margin-top: 20px;
    margin-bottom: 20px;
    align-items: baseline;
    // width: 450px;
    // @media screen and (max-width: 1400px) {
    //   width: 350px;
    // }
    // @media screen and (max-width: 450px) {
    //   width: 100%;
    // }

    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 450px) {
        gap: 16px;
        flex-direction: column;
        width: 100%;
    }

    &__item {
        width: 240px;
        margin-right: 16px;

        @media screen and (max-width: 450px) {
            margin-right: 0;
            width: 100%;
        }

        &__select {
            width: 240px;
            margin-right: 16px;
            border: 1px solid #d9d9d9;

            @media screen and (max-width: 450px) {
                margin-right: 0;
                width: 100%;
            }
        }

        &__date {
            width: 240px;
            margin-right: 16px;

            @media screen and (max-width: 450px) {
                margin-right: 0;
                width: 100%;
            }
        }
    }

    &__segmented {
        width: 100%;

        @media screen and (max-width: 1660px) {
            margin-top: 16px;
        }

        @media screen and (max-width: 450px) {
            margin-top: 0;
        }
    }

    &--no-margin {
        margin-bottom: 0;
    }

    & .ant-radio-button-wrapper:first-child,
    & .ant-radio-button-wrapper:last-child {
        @media screen and (max-width: 450px) {
            border-radius: 0;
        }
    }

    & .ant-radio-group {
        @media screen and (max-width: 450px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    & .ant-radio-button-wrapper {
        @media screen and (max-width: 450px) {
            margin: 5px 0px;
            width: calc(50% - 5px);
            text-align: center;
            padding: 0;
            border-radius: 8px !important;
            border-left-width: 1px;
        }
    }

    & .ant-radio-button-wrapper:not(:first-child)::before {
        display: none;
    }
}