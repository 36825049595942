.div_input {
  width: 100%;
  height: 100%;

  border: 1px solid #cccccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
}
.focused {
  background-color: aliceblue;
  border: 1px solid #1e81df;
}
