@import "../Colors.scss";

.info {
    width: 100%;
    background-color: white;
    padding: 15px;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    .profile__info__password {
        width: 70%;

        .password__result {
            font-size: 15px;
            color: green;
            width: 100%;
            text-align: center;
            margin: 0px;
        }

        .password_input {

            box-sizing: border-box;
            padding: 0px;
            width: 100%;
            height: 50px;
            border: 1px solid #cccccc;
            border-radius: 5px;
            outline: none;
            padding-left: 10px;
            font-size: 18px;

            &:focus {
                background-color: aliceblue;
                border: 1px solid #1e81df;
            }
        }

        .password__error {
            font-size: 15px;
            color: rgb(224, 70, 70);
            width: 100%;
            text-align: center;
            margin: 0px;
        }

        .password_title {
            margin: 0px;
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 20px;
            width: 100%;
            text-align: center;
        }

        .info__input {
            .input_title {
                font-size: 18px;
                margin: 0px;
                margin-top: 10px;
            }
        }


    }

    .info__save_button {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

}