$box-color: #fdfdfd;
$border-primary-color: #3f9bf3;
$blue-color: #0c76da;
$grey-color: #e4e8e7;
$border-radius: 3px;

%div-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%primary-inp {
  outline: none;
  border: 1px solid #00a7b0;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 35px;
}

%error-text {
  color: red;
  font-size: 12;
  margin: 4px;
}
