.header {
  padding: 0 !important;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  // box-shadow: 1px 1px 2px 3px #8c8c8c;
  border-bottom: 1px solid #d9d9d9;

  &__menu-button {
    margin-left: 16px;
    margin-right: 16px;
  }

  &__title-container {
    white-space: nowrap;

    @media screen and (max-width: 450px) {
      max-width: 60%;
    }

    @media screen and (max-width: 370px) {
      max-width: 40%;
    }
  }

  &__title {
    margin-bottom: 3.5px !important;
  }

  &__subtitle {
    opacity: 0.5;
    display: block;
    margin-top: -23px;
    height: 43px;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__cart {
    margin-left: auto;
    margin-right: 20px;
  }
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-avatar-string {
  font-size: 30px;
}