$primary: #1890ff;
$hover: #40a9ff;
$active: #096dd9;
$primary-1: #e6f7ff;
$primary-hover-1: #d5edf8;
$primary-2: #bae7ff;
$primary-3: #91d5ff;
$primary-4: #69c0ff;
$primary-5: #1890ff;
$accent: #3aafb1;
$background : #f0f2f5;
$dark: #001529;
$accent: #3aafb1;