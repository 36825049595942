.work_history {

    .work_history__table {
        padding: 15px;
        background-color: white;
        border-radius: 7px;
        display: "flex";
        flex-direction: flex-end;
    }
}

.deleted {
    background-color: #ffd8d8;
}