@import "../Colors.scss";

.box {
    width: 100%;
    height: 100%;


    .box__default_button {
        background-color: $box-color;
        outline: none;
        width: 100%;
        font-size: 22px;
        height: 100%;
        transition: all 0.6s ease;
        border: 1px solid $border-primary-color;
        border-radius: $border-radius;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
            transition: all 0.6s ease;
            border-color: $border-hover-color;
            background-color: $box-hover-color;
        }

        &:active {
            transition: all 0.1s ease;
            border-color: $border-primary-color;
            background-color: $box-active-color;
        }
    }
}