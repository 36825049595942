.work {
    width: 100%;
    height: 100%;
    padding-right: 5%;
    padding-left: 5%;

    &__result {
        margin-top: 20px;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        max-width: 400px;
        width: 100%;
        font-size: 13px;

        &__param {
            overflow-x: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
        }
    }

    &__card {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        max-width: 400px;
        width: 100%;
        font-size: 16px;

        &__start {
            width: 100%;
            margin-top: 15px;
            display: flex;
            justify-content: flex-end;
        }

        &__serial {
            width: 100%;

            &__scan {
                width: 100%;
                margin-top: 10px;
                margin-bottom: 10px;
                display: flex;
                justify-content: flex-end;
            }

            &__inp {
                width: 100% !important;
            }
        }

        &__operation {
            width: 100%;

            &__label {}

            &__select {
                width: 100%;
                margin-top: 5px !important;
            }
        }

        &__timer {
            margin-top: 20px;
            font-size: 20px;
        }

        &__end {
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: flex-end;

            &__button {}
        }
    }
}


.grey {
    color: #5e5e5e
}