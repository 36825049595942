@import "./Consts.scss";

.main_body {
  height: 100%;
  width: 100%;


  .main__header {

    height: 50px;
    width: 100%;

  }

  .main__right_bar {
    position: absolute;
    right: 0px;
    bottom: 0px;

    width: 20%;

    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;


    .right_bar__keyboard {
      width: 100%;
      border: 1px solid $grey-color;
      background-color: white;
      padding: 5px;
      height: 60%;
      max-height: 300px;

    }

    .service_button {
      position: absolute;
      bottom: 20px;
      width: 100%;
      max-width: 200px;
    }

  }

  .main__bottom_bar {
    display: flex;
    justify-content: flex-end;

    width: 100%;
    height: 8%;

    .bottom_bar__buttons {
      display: flex;
      width: calc(50% + 100px);



    }
  }

  .main__content {
    width: 100%;
    height: 85%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;

    .operation__result {}

    .main__content__error {
      width: 100%;
      text-align: center;
      height: 20px;
      padding: 0px;
      color: rgb(224, 70, 70);
    }



    .main__content__search {
      margin-top: 15px;
      width: 95%;
      max-width: 900px;

    }

    .main__content__date {
      letter-spacing: 0.3px;
      display: flex;
      margin-top: 10px;
      width: 95%;
      max-width: 900px;
      justify-content: flex-end;
      align-items: center;
    }

    .main__content__list {
      margin-top: 15px;
      width: 95%;
      max-width: 900px;
      height: 100%;
      background: #ffffff;

      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

      .operations_title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $grey-color;
        background-color: white;
        height: 50px;
        border-radius: 10px;
        margin-top: 5px;
        font-size: 23px;

      }
    }
  }
}