.search {
    width: 100%;
    height: 100%;
    display: flex;

    .search__inp {
        font-size: 22px;
        width: 100%;
    }

    .search__button {
        width: 15%;
        margin-left: 10px;
    }
}