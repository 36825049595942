.keyboard {
  width: 100%;
  height: 100%;

  .keyboard__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-content: center;
    margin-top: 6px;
    height: 23%;

    .keyboard__row__button {
      border: 1px solid #3f9bf3;
      background-color: aliceblue;
      font-size: 20px;
      color: #3956fa;

      &:hover {
        color: #0e57f7;
        border: 1px solid #2c92f1;
        background-color: rgb(216, 235, 252);
        cursor: pointer;
      }

      &:active {
        border: 1px solid #3f9bf3;
        background-color: rgb(204, 229, 250);
      }

      width: 30%;
      height: 100%;
    }
  }
}